body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* background-image: url("rain-night-city.gif");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-background {
  position: fixed;
  overflow: hidden;
  background-size: cover;
  height: 100vh;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  transform-style: preserve-3d;
  perspective: 1000px;
}

.video-background .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-background .video-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  animation: back-rain-city 2s forwards ease-in;
}

@keyframes back-rain-city {
  /* 50% {
    opacity: 0;
  } */
  100% {
    opacity: 1;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (min-width: 1050px) {
  body {
    /* margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
    /* background-image: url("cyber-city.gif"); */
    /* background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center; */
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background-color: black;
  }

  ::-webkit-scrollbar-thumb {
    background-image: url("gradient.gif");
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-image: linear-gradient(lawngreen, lawngreen);
  }
}
