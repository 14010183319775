.conSideName {
  width: 100px;
  height: 390px;
  /* border: aqua solid 2px; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 23.25%;
  right: 0;
  z-index: 9;
  /* font-family: "Neon Future", sans-serif; */
  /* font-size: 0.6rem;
  color: white;
  text-shadow: 0 0 5rem magenta; */
  transform: translateX(110%);
  /* overflow: hidden; */
  animation: slide-from-right 0.25s forwards ease-in;
  animation-delay: 3.7s;
}

@keyframes slide-from-right {
  0% {
    transform: translateX(110%);
  }
  100% {
    transform: translateX(0);
  }
}

.side-name-board {
  width: 50%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border: aqua solid 5px;
  border-radius: 20px;
  box-shadow: 0 0 0.5rem 0 aqua;
}

.side-name-board p {
  font-size: 0.9rem;
  color: gold;
  text-shadow: 0 0 1.5rem currentColor;
}

.slide-from-left {
  transform: translateX(-100%);
  animation: slide-from-left 0.25s forwards ease-in;
}

.slide-from-left:nth-child(1) {
  animation-delay: 3.95s;
}

.slide-from-left:nth-child(2) {
  animation-delay: 4s;
}

.slide-from-left:nth-child(3) {
  animation-delay: 4.05s;
}

.slide-from-left:nth-child(4) {
  animation-delay: 4.1s;
}

.slide-from-left:nth-child(5) {
  animation-delay: 4.15s;
}

.slide-from-left:nth-child(6) {
  animation-delay: 4.2s;
}

.slide-from-left:nth-child(7) {
  animation-delay: 4.25s;
}

.slide-from-left:nth-child(8) {
  animation-delay: 4.3s;
}

.slide-from-left:nth-child(9) {
  animation-delay: 4.35s;
}

.slide-from-left:nth-child(10) {
  animation-delay: 4.4s;
}

.slide-from-left:nth-child(11) {
  animation-delay: 4.45s;
}

.slide-from-left:nth-child(12) {
  animation-delay: 4.5s;
}

@keyframes slide-from-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.text-hidden {
  opacity: 0;
}

.holding-bars-con {
  width: 50%;
  height: 100%;
  /* border: magenta solid 2px; */
}

.holding-bar-con {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: magenta solid 2px; */
}

.holding-bar {
  width: 100%;
  height: 10px;
  background-color: aqua;
  box-shadow: 0 0 0.75rem 0 aqua;
}
