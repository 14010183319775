@import url("https://fonts.cdnfonts.com/css/neon-vortex");
@import url("https://fonts.cdnfonts.com/css/neon-future");
@import url("https://fonts.cdnfonts.com/css/jethose");
/* @import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap");
@import url("https://fonts.cdnfonts.com/css/galactic-storm");

.App {
  text-align: center;
  color: aqua;
  width: 100%;
  font-family: "Neon Vortex", sans-serif;
  /* overflow: hidden; */
}

.space {
  width: 100%;
  height: 300px;
  /* border: aqua solid 2px; */
}

@media screen and (max-width: 1200px) {
  .space {
    height: 400px;
  }
}
