.portfolio-page {
  /* padding-top: 300px; */
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio-con {
  width: 100%;
  height: 100%;
}

.portfolio-title-before {
  margin: 0 auto;
  margin-top: 95px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border: aqua solid 10px;
  border-radius: 50px;
  box-shadow: inset 0 0 0.75rem 0 aqua, 0 0 0.75rem 0 aqua;
  color: magenta;
  /* text-shadow: 0 0 0.25rem currentColor; */
  font-size: 1.5rem;
  width: fit-content;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
  filter: blur(5px);
  transform: translateX(-100%);
}

.portfolio-title {
  margin: 0 auto;
  margin-top: 95px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border: aqua solid 10px;
  border-radius: 50px;
  box-shadow: inset 0 0 0.75rem 0 aqua, 0 0 0.75rem 0 aqua;
  color: magenta;
  /* text-shadow: 0 0 0.25rem currentColor; */
  font-size: 1.5rem;
  width: fit-content;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
  transition: 0.5s;

  /* filter: blur(5px);
  transform: translateX(-100%); */
  /* animation: slidePortTitle 0.5s forwards ease-in; */
}

/* @keyframes slidePortTitle {
  75% {
    opacity: 1;

    transform: translateX(50%);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
  }
} */

/* #title-flash {
  border: aqua solid 10px;
  border-radius: 50px;
  box-shadow: 0 0 0.5rem 0 aqua;

  animation: title-flash 10s infinite;
} */

/* @keyframes title-flash {
  10% {
    border: aqua, solid 10px;
    box-shadow: 0 0 0.5rem 0 aqua;
    filter: brightness(200%);
  }
  15% {
    border: darkcyan, solid 10px;
    box-shadow: 0 0 0.25rem 0 darkcyan;
    filter: brightness(100%);
  }
  20% {
    border: aqua, solid 10px;
    box-shadow: 0 0 0.5rem 0 aqua;
    filter: brightness(200%);
  }
} */

.project-cards {
  /* border: 2px solid aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: transparent; */
  margin-top: 100px;
}

.project-card-con {
  position: relative;
  border-radius: 20px;
  height: 400px;
  width: 300px;
  /* background: transparent; */
  margin: 50px auto;
  transition: 0.25s;
  transition-delay: 0.5s;
}

.project-card-con-before {
  position: relative;
  border-radius: 20px;
  height: 400px;
  width: 300px;
  /* background: transparent; */
  margin: 50px;
  transform: translateX(-100px);
  filter: blur(20px);
  opacity: 0;
}

.project-card-con:nth-child(2) {
  transition-delay: 0.6s;
}

.project-card-con:nth-child(3) {
  transition-delay: 0.7s;
}

.tilt-con {
  position: relative;
  border-radius: 20px;
  transform-style: preserve-3d;
  height: 400px;
  width: 300px;
}

.tilt-con .project-card {
  position: relative;
  height: 400px;
  width: 300px;

  border: aqua solid 2px;
  box-shadow: 0 0 1.5rem 0 aqua;
  border-radius: 20px;
  background-image: url("matrix-code-purple.gif");
  transform-style: preserve-3d;
}

.non-tilt-con {
  position: relative;
  border-radius: 20px;
  transform-style: flat;
  height: 400px;
  width: 300px;
  /* background: transparent; */
  perspective: none;
}

.non-tilt-con .project-card {
  position: relative;
  height: 400px;
  width: 300px;
  /* transform: translateZ(0); */
  border: aqua solid 2px;
  box-shadow: 0 0 1.5rem 0 aqua;
  border-radius: 20px;
  background-image: url("matrix-code-purple.gif");
  transform-style: flat;
}

.project-card-h1-before,
.project-card-h1-2-before,
.project-card-h1-3-before {
  position: absolute;
  overflow: hidden;
  padding: 5px;
  transform: translateZ(180px);
  top: -5%;
  left: 22%;
  /* width: 50%; */
  filter: brightness(200%);
  font-family: "Orbitron", sans-serif;
  font-size: 1.5rem;
  color: aqua;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border: magenta solid 5px;
  box-shadow: 0 0 1.5rem 0 magenta;
  border-radius: 15px;
  transform: translateY(-100px);
  opacity: 0;
}

.project-card-h1,
.project-card-h1-2,
.project-card-h1-3 {
  position: absolute;
  overflow: hidden;
  padding: 5px;
  transform: translateZ(180px);
  top: -5%;
  left: 22%;
  /* width: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  filter: brightness(200%);
  font-family: "Orbitron", sans-serif;
  font-size: 1.5rem;
  color: aqua;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: magenta solid 5px;
  box-shadow: 0 0 1.5rem 0 magenta;
  border-radius: 15px;
}

.project-card-h1-ios-before,
.project-card-h1-ios-2-before,
.project-card-h1-ios-3-before {
  position: absolute;
  overflow: hidden;
  padding: 5px;
  /* transform: translateZ(180px); */
  top: -5%;
  left: 22%;
  /* width: 50%; */
  filter: brightness(200%);
  font-family: "Orbitron", sans-serif;
  font-size: 1.825rem;
  border: magenta solid 6px;
  border-radius: 17.5px;
  /* font-size: 1.5rem; */
  color: aqua;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  /* border: magenta solid 5px; */
  box-shadow: 0 0 1.5rem 0 magenta;
  /* border-radius: 15px; */
  z-index: 1;
  transform: translateY(-100px);
  opacity: 0;
}

.project-card-h1-ios,
.project-card-h1-ios-2,
.project-card-h1-ios-3 {
  position: absolute;
  overflow: hidden;
  padding: 5px;
  /* transform: translateZ(180px); */
  top: -5%;
  left: 22%;
  /* width: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  filter: brightness(200%);
  font-family: "Orbitron", sans-serif;
  font-size: 1.825rem;
  border: magenta solid 6px;
  border-radius: 17.5px;
  /* font-size: 1.5rem; */
  color: aqua;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  /* border: magenta solid 5px; */
  box-shadow: 0 0 1.5rem 0 magenta;
  /* border-radius: 15px; */
  z-index: 1;
}

.project-card-h1-fx-before,
.project-card-h1-fx-2-before,
.project-card-h1-fx-3-before {
  position: absolute;
  overflow: hidden;
  padding: 5px;
  transform: translateZ(180px);
  top: -5%;
  left: 22%;
  /* width: 50%; */
  filter: brightness(200%);
  font-family: "Orbitron", sans-serif;
  font-size: 1.5rem;
  color: aqua;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(10px);
  border: magenta solid 5px;
  box-shadow: 0 0 1.5rem 0 magenta;
  border-radius: 15px;
  transform: translateY(-100px);
  opacity: 0;
}

.project-card-h1-fx,
.project-card-h1-fx-2,
.project-card-h1-fx-3 {
  position: absolute;
  overflow: hidden;
  padding: 5px;
  transform: translateZ(180px);
  top: -5%;
  left: 22%;
  /* width: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
  filter: brightness(200%);
  font-family: "Orbitron", sans-serif;
  font-size: 1.5rem;
  color: aqua;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border: magenta solid 5px;
  box-shadow: 0 0 1.5rem 0 magenta;
  border-radius: 15px;
}

.project-blur-cover {
  width: 100%;
  height: 100%;
}

@supports (not( backdrop-filter: blur();
)) and (not ( -webkit-backdrop-filter: blur();)) {
  .project-card-h1,
  .project-card-h1-2,
  .project-card-h1-3,
  .project-text,
  .project-text-2,
  .project-text-3,
  .project,
  .project-2,
  .project-3 {
    background-color: rgba(0, 0, 0, 0.8);
  }
}

.project-card-h1 {
  width: 55%;
  left: 19.5%;
  top: 3.5%;
  height: 25px;
  transition: 0.25s;
  transition-delay: 0.8s;
}

.project-card-h1-2 {
  width: 60%;
  height: 25px;
  top: 3.5%;
  left: 17.5%;
  transition: 0.25s;
  transition-delay: 0.9s;
}

.project-card-h1-3 {
  width: 50%;
  height: 25px;
  top: 3.5%;
  transition: 0.25s;
  transition-delay: 1s;
}

.project-card-h1-ios {
  width: 67.5%;
  left: 13%;
  top: -6.5%;
  height: 32.5px;
  transition: 0.25s;
  transition-delay: 0.8s;
}

.project-card-h1-ios-2 {
  width: 73%;
  left: 10%;
  top: -6.5%;
  height: 32.5px;
  transition: 0.25s;
  transition-delay: 0.9s;
}

.project-card-h1-ios-3 {
  width: 67.5%;
  left: 13%;
  top: -6.5%;
  height: 32.5px;
  transition: 0.25s;
  transition-delay: 0.9s;
}

.project-card-h1-fx {
  width: 55%;
  left: 19.5%;
  top: 3.5%;
  height: 25px;
  transition: 0.25s;
  transition-delay: 0.8s;
}

.project-card-h1-fx-2 {
  width: 60%;
  height: 25px;
  top: 3.5%;
  left: 17.5%;
  transition: 0.25s;
  transition-delay: 0.9s;
}

.project-card-h1-fx-3 {
  width: 50%;
  height: 25px;
  top: 3.5%;
  transition: 0.25s;
  transition-delay: 1s;
}

.project-text-before,
.project-text-2-before,
.project-text-3-before {
  position: absolute;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 0.25%;
  padding-bottom: 0.75%;
  transform: translateZ(180px);
  /* top: 14%;
  left: 0%; */
  top: 17%;
  height: 28%;
  font-family: "Orbitron", sans-serif;
  font-size: 1.05rem;
  color: aqua;
  border: lightblue solid 2px;
  border-radius: 20px;
  box-shadow: 0 0 1.5rem 0 lightblue;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transform: translateY(-100px);
  opacity: 0;
}

.project-text,
.project-text-2,
.project-text-3 {
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 0.25%;
  padding-bottom: 0.75%;
  transform: translateZ(180px);
  /* top: 14%;
  left: 0%; */
  top: 17%;
  height: 28%;
  font-family: "Orbitron", sans-serif;
  font-size: 1.05rem;
  color: aqua;
  border: lightblue solid 2px;
  border-radius: 20px;
  box-shadow: 0 0 1.5rem 0 lightblue;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.project-text-ios-before,
.project-text-ios-2-before,
.project-text-ios-3-before {
  position: absolute;
  /* padding-left: 10%;
  padding-right: 10%;
  padding-top: 0.25%;
  padding-bottom: 0.75%; */
  /* transform: translateZ(180px); */
  z-index: 1;
  /* top: 14%;
  left: 0%; */
  /* top: 17%; */
  height: 28%;
  font-family: "Orbitron", sans-serif;
  /* font-size: 1.05rem; */
  color: aqua;
  /* border: lightblue solid 2px;
  border-radius: 20px; */
  box-shadow: 0 0 1.5rem 0 lightblue;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transform: translateY(-100px);
  opacity: 0;
}

.project-text-ios,
.project-text-ios-2,
.project-text-ios-3 {
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-left: 5%;
  padding-right: 5%;
  padding-top: 0.25%;
  padding-bottom: 0.75%; */
  /* transform: translateZ(180px); */
  z-index: 1;
  /* top: 14%;
  left: 0%; */
  /* top: 17%; */
  height: 28%;
  font-family: "Orbitron", sans-serif;
  /* font-size: 1.05rem; */
  color: aqua;
  /* border: lightblue solid 2px;
  border-radius: 20px; */
  box-shadow: 0 0 1.5rem 0 lightblue;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.project-text-ios-before,
.project-text-ios-2-before,
.project-text-ios-3-before,
.project-text-ios,
.project-text-ios-2,
.project-text-ios-3 {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 0.25%;
  padding-bottom: 0.75%;
  width: 100%;
  top: 10%;
  left: -10%;
  height: 34%;
  font-size: 1.25rem;
  border: lightblue solid 3px;
  border-radius: 25px;
}

.project-text-fx-before,
.project-text-fx-2-before,
.project-text-fx-3-before {
  position: absolute;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 0.25%;
  padding-bottom: 0.75%;
  transform: translateZ(180px);
  /* top: 14%;
  left: 0%; */
  top: 17%;
  height: 28%;
  font-family: "Orbitron", sans-serif;
  font-size: 1.05rem;
  color: aqua;
  border: lightblue solid 2px;
  border-radius: 20px;
  box-shadow: 0 0 1.5rem 0 lightblue;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  transform: translateY(-100px);
  opacity: 0;
}

.project-text-fx,
.project-text-fx-2,
.project-text-fx-3 {
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 0.25%;
  padding-bottom: 0.75%;
  transform: translateZ(180px);
  /* top: 14%;
  left: 0%; */
  top: 17%;
  height: 28%;
  font-family: "Orbitron", sans-serif;
  font-size: 1.05rem;
  color: aqua;
  border: lightblue solid 2px;
  border-radius: 20px;
  box-shadow: 0 0 1.5rem 0 lightblue;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.project-text-op-before {
  opacity: 0.8;
  transform: translateY(50%);
  opacity: 0;
}

.project-text-op,
.project-text-op-2,
.project-text-op-3 {
  opacity: 0.8;
  transform: translateY(0);
  transition: 0.25s;
  transition-delay: 1.3s;
}

.project-text-op-2 {
  transition-delay: 1.4s;
}

.project-text-op-3 {
  transition-delay: 1.5s;
}

.project-text {
  /* top: 17%;
  height: 28%; */
  transition: 0.25s;
  transition-delay: 1.1s;
}

.project-text-2 {
  /* top: 17%;
  height: 28%; */
  transition: 0.25s;
  transition-delay: 1.2s;
}

.project-text-3 {
  /* top: 17%;
  height: 28%; */
  transition: 0.25s;
  transition-delay: 1.3s;
}

.project-text-ios {
  /* top: 17%;
  height: 28%; */
  transition: 0.25s;
  transition-delay: 1.1s;
}

.project-text-ios-2 {
  /* top: 17%;
  height: 28%; */
  transition: 0.25s;
  transition-delay: 1.2s;
}

.project-text-ios-3 {
  /* top: 17%;
  height: 28%; */
  transition: 0.25s;
  transition-delay: 1.3s;
}

.project-text-fx {
  /* top: 17%;
  height: 28%; */
  transition: 0.25s;
  transition-delay: 1.1s;
}

.project-text-fx-2 {
  /* top: 17%;
  height: 28%; */
  transition: 0.25s;
  transition-delay: 1.2s;
}

.project-text-fx-3 {
  /* top: 17%;
  height: 28%; */
  transition: 0.25s;
  transition-delay: 1.3s;
}

.project-before {
  position: absolute;
  overflow: hidden;
  padding: 5px;
  transform: translateZ(180px);
  top: 50%;
  left: 13.5%;
  width: 70%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Orbitron", sans-serif;
  /* color: gold; */
  text-shadow: 0 0 0.375rem currentColor;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border: aqua solid 2px;
  box-shadow: 0 0 0.375rem 0 aqua;
  border-radius: 15px;
  transform: translateY(-100px);
  opacity: 0;
}

.project,
.project-2,
.project-3 {
  position: absolute;
  overflow: hidden;
  padding: 5px;
  transform: translateZ(180px);
  top: 50%;
  left: 13.5%;
  width: 70%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Orbitron", sans-serif;
  /* color: gold; */
  text-shadow: 0 0 0.375rem currentColor;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border: aqua solid 2px;
  box-shadow: 0 0 0.375rem 0 aqua;
  border-radius: 15px;
  transition: 0.25s;
  transition-delay: 1.4s;
}

.project-ios-before {
  position: absolute;
  overflow: hidden;
  /* padding: 5px; */
  /* transform: translateZ(180px); */
  z-index: 1;
  /* top: 50%;
  left: 13.5%;
  width: 70%;
  height: 50%; */
  top: 50%;
  left: 4.3%;
  width: 86%;
  height: 61%;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Orbitron", sans-serif;
  /* color: gold; */
  text-shadow: 0 0 0.375rem currentColor;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  /* border: aqua solid 2px; */
  border-radius: 20px;
  border: aqua solid 3px;
  box-shadow: 0 0 0.375rem 0 aqua;
  /* border-radius: 15px; */
  transform: translateY(-100px);
  opacity: 0;
}

.project-ios,
.project-ios-2,
.project-ios-3 {
  position: absolute;
  overflow: hidden;
  /* padding: 5px; */
  /* transform: translateZ(180px); */
  z-index: 1;
  /* top: 50%;
  left: 13.5%;
  width: 70%;
  height: 50%; */
  top: 50%;
  left: 4.3%;
  width: 86%;
  height: 61%;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Orbitron", sans-serif;
  /* color: gold; */
  text-shadow: 0 0 0.375rem currentColor;
  background-color: rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  /* border: aqua solid 2px; */
  border-radius: 20px;
  border: aqua solid 3px;
  box-shadow: 0 0 0.375rem 0 aqua;
  /* border-radius: 15px; */
  transition: 0.25s;
  transition-delay: 1.4s;
}

/* .project-before-ios,
.project-ios,
.project-ios-2,
.project-ios-3 {


} */

.project-fx-before {
  position: absolute;
  overflow: hidden;
  padding: 5px;
  transform: translateZ(180px);
  top: 50%;
  left: 13.5%;
  width: 70%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Orbitron", sans-serif;
  /* color: gold; */
  text-shadow: 0 0 0.375rem currentColor;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border: aqua solid 2px;
  box-shadow: 0 0 0.375rem 0 aqua;
  border-radius: 15px;
  transform: translateY(-100px);
  opacity: 0;
}

.project-fx,
.project-fx-2,
.project-fx-3 {
  position: absolute;
  overflow: hidden;
  padding: 5px;
  transform: translateZ(180px);
  top: 50%;
  left: 13.5%;
  width: 70%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Orbitron", sans-serif;
  /* color: gold; */
  text-shadow: 0 0 0.375rem currentColor;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border: aqua solid 2px;
  box-shadow: 0 0 0.375rem 0 aqua;
  border-radius: 15px;
  transition: 0.25s;
  transition-delay: 1.4s;
}

.project-2 {
  transition-delay: 1.5s;
}

.project-3 {
  transition-delay: 1.6s;
}

.project-ios-2 {
  transition-delay: 1.5s;
}

.project-ios-3 {
  transition-delay: 1.6s;
}

.project-fx-2 {
  transition-delay: 1.5s;
}

.project-fx-3 {
  transition-delay: 1.6s;
}

.project-img-before,
.project-img-2-before,
.project-img-3-before {
  width: 99.99%;
  height: 99.99%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transform: translateY(50%);
  opacity: 0;
}

.project-img,
.project-img-2,
.project-img-3 {
  width: 99.99%;
  height: 99.99%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.25s;
}

.project-img {
  background-image: url("api.webp");
  transition-delay: 1.6s;
}

.project-img-2 {
  background-image: url("NC-News.webp");
  border-radius: 10px;
  transition-delay: 1.7s;
}

.project-img-3 {
  background-image: url("ingredient-list.webp");
  border-radius: 10px;
  background-position: top;
  transition-delay: 1.8s;
}

.visit-button-before {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateZ(270px);
  bottom: 0%;
  left: 13.5%;
  width: 15%;
  height: 8%;
  filter: brightness(200%);
  /* border: lightblue solid 2px;
  box-shadow: 0 0 0.5rem 0 lightblue; */
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(5px);
  /* font-family: "Orbitron", sans-serif;
  color: gold;
  font-size: 0.7rem;
  font-weight: bold;
  text-decoration: none; */
  transform: translateY(-100px);
  opacity: 0;
}

.visit-button,
.visit-button-2,
.visit-button-3 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateZ(270px);
  bottom: 0%;
  left: 13.5%;
  width: 15%;
  height: 8%;
  filter: brightness(200%);
  /* border: lightblue solid 2px;
  box-shadow: 0 0 0.5rem 0 lightblue; */
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(5px);
  /* font-family: "Orbitron", sans-serif;
  color: gold;
  font-size: 0.7rem;
  font-weight: bold;
  text-decoration: none; */
  transition: 0.25s;
  transition-delay: 1.7s;
}

.visit-button-2 {
  transition-delay: 1.8s;
}

.visit-button-3 {
  transition-delay: 1.9s;
}

.visit-button-ios-before {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: translateZ(270px); */
  z-index: 2;
  /* bottom: 0%;
  left: 13.5%;
  width: 15%;
  height: 8%; */
  filter: brightness(200%);
  /* border: lightblue solid 2px;
  box-shadow: 0 0 0.5rem 0 lightblue; */
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(5px);
  /* font-family: "Orbitron", sans-serif;
  color: gold;
  font-size: 0.7rem;
  font-weight: bold;
  text-decoration: none; */
  transform: translateY(-100px);
  opacity: 0;
}

.visit-button-ios,
.visit-button-ios-2,
.visit-button-ios-3 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: translateZ(270px); */
  z-index: 2;
  /* bottom: 0%;
  left: 13.5%;
  width: 15%;
  height: 8%; */
  filter: brightness(200%);
  /* border: lightblue solid 2px;
  box-shadow: 0 0 0.5rem 0 lightblue; */
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(5px);
  /* font-family: "Orbitron", sans-serif;
  color: gold;
  font-size: 0.7rem;
  font-weight: bold;
  text-decoration: none; */
  transition: 0.25s;
  transition-delay: 1.7s;
}

.visit-button-ios-2 {
  transition-delay: 1.8s;
}

.visit-button-ios-3 {
  transition-delay: 1.9s;
}

.visit-button-ios-before,
.visit-button-ios,
.visit-button-ios-2,
.visit-button-ios-3 {
  bottom: -19%;
  left: 0%;
  width: 20%;
  height: 11%;
}

.go-button:hover {
  filter: brightness(300%);
  border: goldenrod solid 2px;
  background-color: goldenrod;
  box-shadow: 0 0 1.5rem 0 goldenrod;
  border: gold solid 2px;
  color: black;
}

.go-button {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  font-family: "Orbitron", sans-serif;
  color: gold;
  border: lightblue solid 2px;
  box-shadow: 0 0 0.75rem 0 lightblue;
  font-size: 1.05rem;
  font-weight: bold;
  text-decoration: none;
}

.go-button-ios:hover {
  filter: brightness(300%);
  border: goldenrod solid 2px;
  background-color: goldenrod;
  box-shadow: 0 0 1.5rem 0 goldenrod;
  border: gold solid 2px;
  color: black;
}

.go-button-ios {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 13px;
  font-family: "Orbitron", sans-serif;
  color: gold;
  border: lightblue solid 3px;
  box-shadow: 0 0 0.75rem 0 lightblue;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
}

.project-h1-name-before {
  transform: translateY(100%);
  opacity: 0;
}

.project-h1-name {
  transform: translateY(0);
  transition: 0.25s;
  transition-delay: 1s;
}

.project-h1-name-2 {
  transform: translateY(0);
  transition: 0.25s;
  transition-delay: 1.1s;
}

.project-h1-name-3 {
  transform: translateY(0);
  transition: 0.25s;
  transition-delay: 1.2s;
}

.project-h1-name-ios-before {
  transform: translateY(100%);
  opacity: 0;
}

.project-h1-name-ios {
  transform: translateY(0);
  transition: 0.25s;
  transition-delay: 1s;
}

.project-h1-name-ios-2 {
  transform: translateY(0);
  transition: 0.25s;
  transition-delay: 1.1s;
}

.project-h1-name-ios-3 {
  transform: translateY(0);
  transition: 0.25s;
  transition-delay: 1.2s;
}

@media screen and (max-width: 1200px) {
  .portfolio-page {
    height: 200vh;
    margin-top: 400px;
  }

  .portfolio-title {
    font-size: 1.125rem;
    margin-bottom: 125px;
    /* transform: translateX(0); */
    /* transform: translateY(200px); */
    /* animation: slidePortTitle 0.5s forwards ease-in; */
  }

  .portfolio-title-before {
    font-size: 1.125rem;
    transform: translateX(0);
    transform: translateY(200px);
  }

  .project-card-con {
    margin-bottom: 100px;
  }

  .project-card-con-before {
    /* margin: 0; */
    margin-bottom: 100px;
    transform: translateX(0);
    transform: translateY(200px);
    /* transition-delay: 0s; */
  }

  .project-cards {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin-top: 50px; */
  }

  .project-card-h1-before,
  .project-card-h1-2-before,
  .project-card-h1-3-before,
  .project-card-h1,
  .project-card-h1-2,
  .project-card-h1-3,
  .project-text-before,
  .project-text-2-before,
  .project-text-3-before,
  .project-text,
  .project-text-2,
  .project-text-3,
  .project-before,
  .project,
  .project-2,
  .project-3 {
    transform: none;
    z-index: 1;
  }

  .visit-button-before,
  .visit-button,
  .visit-button-2,
  .visit-button-3 {
    transform: none;
    z-index: 2;
    bottom: -19%;
    left: 0%;
    width: 20%;
    height: 11%;
  }

  .go-button {
    font-size: 1.5rem;
    border: lightblue solid 3px;
    border-radius: 13px;
  }

  .project-card-h1-before,
  .project-card-h1-2-before,
  .project-card-h1-3-before,
  .project-card-h1,
  .project-card-h1-2,
  .project-card-h1-3 {
    font-size: 1.825rem;
    border: magenta solid 6px;
    border-radius: 17.5px;
  }

  .project-card-h1 {
    width: 67.5%;
    left: 13%;
    top: -6.5%;
    height: 32.5px;
    /* transition: 0.25s;
    transition-delay: 0.8s; */
  }

  .project-card-h1-2 {
    width: 73%;
    left: 10%;
    top: -6.5%;
    height: 32.5px;
  }

  .project-card-h1-3 {
    width: 67.5%;
    left: 13%;
    top: -6.5%;
    height: 32.5px;
  }

  .project-text-before,
  .project-text-2-before,
  .project-text-3-before,
  .project-text,
  .project-text-2,
  .project-text-3 {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 0.25%;
    padding-bottom: 0.75%;
    width: 100%;
    top: 10%;
    left: -10%;
    height: 34%;
    font-size: 1.25rem;
    border: lightblue solid 3px;
    border-radius: 25px;
  }

  .project-before,
  .project,
  .project-2,
  .project-3 {
    top: 50%;
    left: 4.3%;
    width: 86%;
    height: 61%;
    padding: 7px;
    border-radius: 20px;
    border: aqua solid 3px;
  }

  .project-img-before,
  .project-img-2-before,
  .project-img-3-before,
  .project-img,
  .project-img-2,
  .project-img-3 {
    border-radius: 15px;
  }
}

@media screen and (max-width: 411px) {
  /* .project-text,
  .project-text-2,
  .project-text-3,
  .project-text-before,
  .project-text-2-before,
  .project-text-3-before {
    padding-left: 2.5%;
    padding-right: 2.5%;
  } */

  .tilt-con .project-card,
  .non-tilt-con .project-card {
    width: 290.5px;
  }

  .tilt-con,
  .non-tilt-con,
  .project-card-con,
  .project-card-con-before {
    width: 290.5px;
  }
}

@media screen and (max-width: 380px) {
  .portfolio-page {
    height: 275vh;
  }

  /* .project-text,
  .project-text-2,
  .project-text-3,
  .project-text-before,
  .project-text-2-before,
  .project-text-3-before {
    padding-left: 2.5%;
    padding-right: 2.5%;
  } */

  .tilt-con .project-card,
  .non-tilt-con .project-card {
    width: 272.5px;
  }

  .tilt-con,
  .non-tilt-con,
  .project-card-con,
  .project-card-con-before {
    width: 272.5px;
  }
}

@media screen and (max-height: 500px) {
  .portfolio-page {
    height: 400vh;
  }
}
