.footer {
  padding-top: 200px;
  padding-bottom: 25px;
  width: 100%;
  font-family: "Orbitron", sans-serif;
  font-size: 1.125rem;
  color: white;
  /* text-shadow: 0 0 0.25rem darkblue; */
}

.footer-con {
  width: 45%;
  margin: auto;
  padding: 1px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  /* box-shadow: 0 0 0.05rem 0 aqua; */
}

.footer-icon a {
  text-decoration: none;
  color: aqua;
}

@media screen and (max-width: 1200px) {
  .footer {
    padding-top: 50px;
    padding-bottom: 150px;
  }
  .footer-con {
    width: 90%;
  }
}

@media screen and (max-height: 500px) {
  .footer {
    padding-bottom: 50px;
  }

  .footer-con {
    width: 100%;
    padding: 5px;
  }
}
