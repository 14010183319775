.contact-page {
  /* margin-top: 300px; */
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Orbitron", sans-serif;
}

.contact-con-side {
  width: 75%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: aqua solid 2px; */
  transition: 0.5s;
}

.contact-con-side-before {
  width: 75%;
  height: 100%;
  /* border: aqua solid 2px; */
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-100%);
  opacity: 0;
  transition: 0.5s;
}

@property --gradient-angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

:root {
  --clr-6: #5ddcff;
  --clr-7: #3c67e3;
  --clr-8: magenta;
  /* --clr-6: magenta;
  --clr-7: #3c67e3;
  --clr-8: purple; */
  /* --clr-6: lawngreen;
  --clr-7: green;
  --clr-8: darkgreen; */
}

.contact-con {
  height: 85vh;
  aspect-ratio: 1 / 0.85;
  background-color: rgb(40, 1, 55);
  /* background-image: url("rain-night-city.gif");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  border-radius: 1.5rem;

  position: relative;
}

.contact-con::before,
.contact-con::after {
  content: "";
  position: absolute;
  inset: -0.7125rem;
  z-index: -1;
  background: conic-gradient(
    from var(--gradient-angle),
    var(--clr-6),
    var(--clr-7),
    var(--clr-8),
    var(--clr-7),
    var(--clr-6)
  );
  border-radius: inherit;
  animation: rotation 5s linear infinite;
}

.contact-con::after {
  filter: blur(3rem);
}

@keyframes rotation {
  /* 0% {
    --gradient-angle: 0deg;
  }
  100% {
    --gradient-angle: 360deg;
  } */
  from {
    --gradient-angle: 0deg;
  }
  to {
    --gradient-angle: 360deg;
  }
}

.contact-me {
  width: 100%;
  /* font-family: "Orbitron", sans-serif; */
  color: cyan;
  letter-spacing: 0.15rem;
}

.contact-me-h1-con {
  overflow: hidden;
  width: 50%;
  margin: auto;
  font-size: 1.5rem;
}

.contact-me-h1-before {
  transform: translateY(100%);
  opacity: 0;
}

.contact-me-h1 {
  transition: 0.25s;
  transition-delay: 0.5s;
}

.me-text {
  color: magenta;
}

form {
  text-align: left;
  display: block;
  padding: 30px;
  color: white;
  font-size: 1.125rem;
  transform: translateY(-10%);
}

form input,
textarea {
  display: flex;
  flex-direction: column;
  background-color: cyan;
  width: 100%;
  margin: 10px auto;
  padding: 5px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-family: "Orbitron", sans-serif;
  font-size: 16px;
}

form input:focus,
textarea:focus {
  box-shadow: 0 0 0.75rem 0 cyan;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px aqua inset !important;
} */

form button {
  margin-top: 10px;
  border: magenta solid 2px;
  background-color: transparent;
  border-radius: 5px;
  font-family: "Orbitron", sans-serif;
  font-size: 1.125rem;
  font-weight: bold;
  color: magenta;
  padding: 6px;
  box-shadow: inset 0 0 0.15rem 0 magenta, 0 0 0.375rem 0 magenta;
}

form button:hover {
  background-color: magenta;
  box-shadow: 0 0 1.5rem 0 magenta;
  color: black;
  cursor: pointer;
}

form button:active {
  transform: scale(1.1);
}

.contact-details {
  text-align: left;
  transform: translateY(-50%);
  width: 100%;
  /* border: 2px solid aqua; */
  padding: 0px;
  color: white;
  font-size: 1.275rem;
  padding-left: 30px;
}

.contact-detail-before {
  margin-bottom: 15px;
  transform: translateY(100%);
  opacity: 0;
}

.contact-detail {
  margin-bottom: 15px;
  transition: 0.25s;
  transition-delay: 0.8s;
}

.contact-detail:nth-child(2) {
  transition-delay: 0.85s;
}

.contact-detail:nth-child(3) {
  transition-delay: 0.9s;
}

.contact-me i {
  color: aqua;
}

.name-email {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105%;
  /* border: 2px solid gold; */
  transition: 0.25s;
  transition-delay: 0.55s;
}

.name-email-before {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105%;
  transform: translateY(100%);
  opacity: 0;
}

.name-col {
  /* display: flex;
  flex-direction: column; */

  width: 100%;
}
.name-col input {
  float: left;
  width: 90%;
}

.mandatory {
  filter: brightness(200%);
  color: gold;
  text-shadow: 0 0 0.375rem currentColor;
}

.contact-subject {
  transition: 0.25s;
  transition-delay: 0.6s;
}

.contact-subject-before {
  transform: translateY(100%);
  opacity: 0;
}

.contact-mesg {
  transition: 0.25s;
  transition-delay: 0.65s;
}

.contact-mesg-before {
  transform: translateY(100%);
  opacity: 0;
}

.contact-btn {
  transition: 0.25s;
  transition-delay: 0.75s;
}

.contact-btn-before {
  transform: translateY(100%);
  opacity: 0;
}

.contact-btn:active {
  transform: scale(1.1);
  transform: translateY(0);
}

@media screen and (max-width: 1050px) {
  .contact-page {
    height: 200vh;
    margin-bottom: 200px;
  }

  .contact-con {
    height: 750px;
    aspect-ratio: 1 / 2.45;
  }

  .contact-con-side-before {
    transform: translateX(0);
    transform: translateY(200px);
  }

  .contact-me-h1-before,
  .contact-me-h1 {
    font-size: 2.55rem;
  }

  .contact-me-h1-con {
    width: 100%;
    margin-bottom: 20px;
  }

  .name-email,
  .name-email-before {
    flex-direction: column;
    width: 100%;
  }

  .name-col input {
    width: 100%;
  }

  form {
    font-size: 1.05rem;
  }

  .contact-details {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 411px) {
  form {
    padding: 22.5px;
  }

  .contact-details {
    padding-left: 22.5px;
  }
}

@media screen and (min-height: 500px) and (max-height: 600px) {
  .contact-page {
    height: 400vh;
  }

  .contact-con {
    height: 150vh;
  }
}

@media screen and (min-height: 660px) and (max-height: 680px) {
  .contact-page {
    height: 113vh;
  }

  .contact-con {
    height: 113vh;
  }
}

@media screen and (min-height: 680px) and (max-height: 700px) {
  .contact-con {
    height: 109vh;
  }
}

@media screen and (min-height: 700px) and (max-height: 720px) {
  .contact-con {
    height: 106vh;
  }
}

@media screen and (min-height: 720px) and (max-height: 740px) {
  .contact-con {
    height: 103.5vh;
  }
}

@media screen and (min-height: 740px) and (max-height: 760px) {
  .contact-con {
    height: 101vh;
  }
}

@media screen and (min-height: 760px) and (max-height: 780px) {
  .contact-con {
    height: 98.5vh;
  }
}

@media screen and (min-height: 780px) and (max-height: 800px) {
  .contact-con {
    height: 96vh;
  }
}

@media screen and (min-height: 800px) and (max-height: 820px) {
  .contact-con {
    height: 93.5vh;
  }
}

@media screen and (min-height: 820px) and (max-height: 840px) {
  .contact-con {
    height: 91vh;
  }
}

@media screen and (min-height: 840px) and (max-height: 860px) {
  .contact-con {
    height: 88.5vh;
  }
}

@media screen and (min-height: 860px) and (max-height: 869px) {
  .contact-con {
    height: 88vh;
  }
}

@media screen and (max-height: 500px) {
  .contact-page {
    height: 400vh;
  }

  .contact-con {
    height: 210vh;
  }
}

@media screen and (min-height: 700px) and (max-height: 800px) {
  .contact-page {
    margin-top: 300px;
  }
}

@media screen and (min-height: 1000px) {
  .contact-con {
    height: 75vh;
    aspect-ratio: 1 / 1.5;
  }
}

@media screen and (min-height: 1100px) {
  .contact-con {
    height: 70vh;
    aspect-ratio: 1 / 1.25;
  }
}

@media screen and (min-height: 1200px) {
  .contact-con {
    height: 60vh;
    aspect-ratio: 1 / 1.25;
  }
}

@media screen and (min-width: 900px) and (max-width: 1100px) and (min-height: 700px) and (max-height: 800px) {
  .contact-con {
    aspect-ratio: 1 / 1.45;
  }
}

@media screen and (min-width: 1050px) and (max-width: 1200px) and (min-height: 800px) and (max-height: 900px) {
  .contact-page {
    height: 200vh;
  }
}
