@import url("https://fonts.cdnfonts.com/css/neon-pixel-7");
@import url("https://fonts.cdnfonts.com/css/gran-torino");

/* @font-face {
  font-family: neon;
  src: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/707108/neon.ttf);
} */

nav {
  /* background-color: rgba(40, 1, 55, 0.9);
  backdrop-filter: blur(5px);
  border-bottom: solid 5px #d400d4; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background-color: rgba(40, 1, 55, 0.9);
  backdrop-filter: blur(5px);
  border-bottom: solid 5px #d400d4;
  justify-content: right;

  align-items: center;
  display: flex;
  box-shadow: 0 0 1em 0 #d400d4;
  z-index: 10;
  font-size: 1.35rem;
}

.active {
  visibility: visible;
  transition: all 0.5s;
}

.hidden {
  visibility: hidden;
  transition: all 0.5s;
  transform: translateY(-100%);
}
#bounce {
  animation: navBounce 3.5s ease-in;
  /* animation-delay: 9.5s;
  opacity: 0; */
}

@keyframes navBounce {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  84% {
    transform: translateY(-100%);
    opacity: 0;
  }
  85% {
    transform: translateY(-100%);
    opacity: 1;
  }
  88% {
    transform: translateY(0);
  }
  91% {
    transform: translateY(-40%);
  }
  94% {
    transform: translateY(0);
  }
  97% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.nav-menu {
  float: right;
  margin-right: 35px;
  list-style: none;
}

.con {
  transform: translateY(-3%);
  overflow: hidden;
  width: fit-content;
  height: 58px;
  /* float: left; */
  top: 0;
  left: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 11;
}

.logo {
  filter: brightness(125%);
  font-size: 2.25rem;
  font-family: "Gran Torino", sans-serif;
  text-decoration: none;
  float: left;
  margin: 12.5px 0 0 35px;
  color: #fed128;
  text-shadow: 0 0 1rem #f40a35;
  margin-right: 4px;
  margin-bottom: 4px;
}

#logoSlide {
  transform: translateY(50px);
  transition: transform 0.5s;
}

nav .ul {
  /* border: aqua solid 2px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

nav .ul .li {
  /* border: gold solid 2px; */
  font-weight: bold;
  color: aqua;
  display: inline-block;
  /* margin-right: 30px; */
  width: 220px;
  padding: 5px;
  border-radius: 5px;
  letter-spacing: 1px;
}

nav .ul .li:hover {
  /* border: aqua solid 2px;
  box-shadow: 0 0 1rem 0 aqua;
  background-color: aqua;
  color: darkblue; */
  cursor: pointer;
  color: white;
  text-shadow: 0 0 1.5rem magenta;
}

/* nav ul li .active {
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 0 1rem 0 aqua;
  background-color: aqua;
  color: darkblue;
} */

.link-active {
  /* color: white;
  filter: drop-shadow(0 0 5px magenta) drop-shadow(0 0 40px magenta)
    drop-shadow(0 0 60px magenta); */
  border: aqua solid 2px;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 0 1.5rem 0 aqua;
  background-color: aqua;
  color: darkblue;
}

.link1 {
  border: transparent solid 2px;
  padding: 5px;
  border-radius: 5px;
  /* box-shadow: 0 0 0.25rem 0 aqua; */
  opacity: 0;
  filter: blur(5px);
  transform: translateY(-100%);
  animation: slideAbout 0.5s forwards ease-in;
  animation-delay: 3.3s;
}

@keyframes slideAbout {
  75% {
    opacity: 1;
    /* filter: blur(0); */
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }
}

.link2 {
  border: transparent solid 2px;
  padding: 5px;
  border-radius: 5px;
  /* box-shadow: 0 0 0.25rem 0 aqua; */
  opacity: 0;
  filter: blur(5px);
  transform: translateY(-100%);
  animation: slidePort 0.5s forwards ease-in;
  animation-delay: 3.4s;
}

@keyframes slidePort {
  75% {
    opacity: 1;
    /* filter: blur(0); */
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }
}

.link3 {
  border: transparent solid 2px;
  padding: 5px;
  border-radius: 5px;
  /* box-shadow: 0 0 0.25rem 0 aqua; */
  opacity: 0;
  filter: blur(5px);
  transform: translateY(-100%);
  animation: slideContact 0.5s forwards ease-in;
  animation-delay: 3.5s;
}

@keyframes slideContact {
  75% {
    opacity: 1;
    /* filter: blur(0); */
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }
}

#mobile-nav {
  display: none;
}

@media screen and (max-width: 1200px) {
  .nav-menu {
    margin-right: 0;
  }

  nav .ul .ul-mobile {
    /* border: aqua solid 2px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: fixed;
    top: 70px;
    right: -340px;
    width: 300px;
    height: 100vh;
    padding-top: 20px;
    padding-left: 40px;
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(10px);
    transition: 0.3s;
  }
  /* 
  nav .ul .ul-mobile {

  } */

  nav .ul .ul-mobile.live {
    right: 0;
  }

  #mobile-nav {
    display: block;
  }

  nav .ul .li {
    text-align: left;
    color: white;
    text-shadow: 0 0 1.5rem magenta;
    margin-bottom: 25px;
    font-size: 25px;
  }

  .hamburger-wrapper {
    position: relative;
    display: inline-block;
    margin-right: 25px;
    /* width: 40px;
  height: 40px;
  box-shadow: 0 0 2.5px magenta, 0 0 5px magenta, 0 0 10px magenta,
    0 0 20px magenta;
  border-radius: 10px; */
    cursor: pointer;
    display: flex;
  }

  .hamburger-icon {
    position: relative;
    width: 35px;
    height: 26px;
    /* background: magenta; */
    margin: auto;
    display: flex;
    align-items: center;
    transform: rotate(0);
    transition: 0.75s;
  }

  .hamburger-wrapper.live .hamburger-icon {
    transform: rotate(315deg);
  }

  .hamburger-icon span {
    position: absolute;
    display: block;

    height: 4px;
    background: linear-gradient(45deg, magenta, transparent);
    border: 1px solid magenta;
    border-radius: 10px;
    box-shadow: 0 0 1px magenta, 0 0 2px magenta;
    transition: 0.3s;
  }

  .hamburger-icon span:nth-child(1) {
    top: 0;
    right: 0;
    width: 100%;
    transform: translateY(0);
  }

  .hamburger-wrapper.live span:nth-child(1) {
    width: 100%;
    transform: translateY(8px);
  }

  .hamburger-icon span:nth-child(2) {
    width: 75%;
    right: 0;
    transform: scaleX(1);
    opacity: 1;
  }

  .hamburger-wrapper.live span:nth-child(2) {
    transform: scaleX(0);
    opacity: 0;
  }

  .hamburger-icon span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 50%;
    transform: rotate(0);
  }

  .hamburger-wrapper.live span:nth-child(3) {
    bottom: 12px;
    width: 100%;
    transform: rotate(90deg);
  }

  .con {
    transform: translateX(-10%);
  }

  .logo:active {
    font-size: 2.625rem;
    filter: drop-shadow(0 0 1px #f40a35) drop-shadow(0 0 2px #f40a35)
      drop-shadow(0 0 3px #f40a35);
    cursor: pointer;
  }
}

@media screen and (min-width: 1200px) {
  .logo:hover {
    font-size: 2.625rem;
    filter: drop-shadow(0 0 1px #f40a35) drop-shadow(0 0 2px #f40a35)
      drop-shadow(0 0 3px #f40a35);
    cursor: pointer;
  }
}
