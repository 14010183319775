@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap");
@import url("https://fonts.cdnfonts.com/css/neon-future");

.pic-con {
  width: 300px;
  height: 300px;
  background-color: cyan;
  box-shadow: 0 0 1em 0 cyan;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-page {
  width: 100%;
  height: 110vh;
}

.pic-me {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background-image: url("me-pizzahut-cut.webp");
  background-size: 375px 337.5px;
  background-position: center;
  background-repeat: none;
  animation: back-pix 12s ease-in infinite;
  animation-delay: 1s;
}

#pic-float {
  animation: float 8s linear infinite;
}

@keyframes float {
  0% {
    transform: translateY(0%);
  }
  1% {
    transform: translateY(0%);
  }
  49.5% {
    transform: translateY(-60%);
  }
  50.5% {
    transform: translateY(-60%);
  }
  99% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes back-pix {
  0% {
    background-image: url("me-pizzahut-cut.webp");
  }
  50% {
    background-image: url("me-pizzahut-pixel-2.webp");
  }
  100% {
    background-image: url("me-pizzahut-cut.webp");
  }
}

.back-blurred {
  width: 99.99%;
  height: 99.99%;
  backdrop-filter: blur(40px);
  border-radius: 1.5rem;
  font-size: 1.125rem;
  text-align: left;
  overflow: hidden;
  border-radius: 1.5rem;
}

.about-me {
  margin: 20px;
  margin-top: 30px;
  /* font-family: "Neon Future", sans-serif; */
  color: orangered;
  /* font-size: 2rem; */
  font-size: 1.875rem;
  font-weight: bolder;
  /* text-shadow: 0 0 0.25rem currentColor; */
  transition: 0.5s;
  transition-delay: 0.3s;
}

.about-me-before {
  margin: 20px;
  margin-top: 30px;
  /* font-family: "Neon Future", sans-serif; */
  color: orangered;
  /* font-size: 2rem; */
  font-size: 1.875rem;
  font-weight: bolder;
  /* text-shadow: 0 0 0.25rem currentColor; */
  opacity: 0;
  transform: translateX(-80%);
}

.about-text {
  margin: 20px;
  font-family: "Orbitron", sans-serif;
  font-size: 0.9rem;
  color: gold;
  /* text-shadow: 0 0 0.5rem currentColor; */
  transition: 0.5s;
  transition-delay: 0.3s;
}

.about-text-before {
  margin: 20px;
  font-family: "Orbitron", sans-serif;
  font-size: 0.9rem;
  color: gold;
  /* text-shadow: 0 0 0.5rem currentColor; */
  transform: translateY(100px);
  opacity: 0;
}

.about-text-2 {
  margin: 20px;
  font-family: "Orbitron", sans-serif;
  font-size: 0.9rem;
  color: gold;
  /* text-shadow: 0 0 0.5rem currentColor; */
  transition: 0.5s;
  transition-delay: 0.4s;
}

.about-text-before-2 {
  margin: 20px;
  font-family: "Orbitron", sans-serif;
  font-size: 0.9rem;
  color: gold;
  /* text-shadow: 0 0 0.5rem currentColor; */
  transform: translateY(100px);
  opacity: 0;
}

.about-text-3 {
  margin: 20px;
  font-family: "Orbitron", sans-serif;
  font-size: 0.9rem;
  color: gold;
  /* text-shadow: 0 0 0.5rem currentColor; */
  transition: 0.5s;
  transition-delay: 0.5s;
}

.about-text-before-3 {
  margin: 20px;
  font-family: "Orbitron", sans-serif;
  font-size: 0.9rem;
  color: gold;
  /* text-shadow: 0 0 0.5rem currentColor; */
  transform: translateY(100px);
  opacity: 0;
}

.light-text {
  opacity: 0.65;
  color: aqua;
}

.tech {
  color: aqua;
  font-weight: bolder;
  margin-bottom: 10px;
}

.tech-icons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.7rem;
}

.tech-icon {
  position: relative;
  overflow: hidden;
  width: 46.5px;
  height: 60px;
  font-family: "Orbitron", sans-serif;
  font-size: 0.75em;
  text-align: center;
  text-shadow: 0 0 0.2rem currentColor;
  border: aqua solid 1px;
  box-shadow: 0 0 0.25rem 0 aqua;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 5px;
  margin: 7.5px;
  margin-top: 10px;
  padding: 10px;
  transition: 0.5s;
  transition-delay: 0.6s;
}

.tech-icon:nth-child(2) {
  transition-delay: 0.7s;
}

.tech-icon:nth-child(3) {
  transition-delay: 0.8s;
}

.tech-icon:nth-child(4) {
  transition-delay: 0.9s;
}

.tech-icon-before {
  position: relative;
  overflow: hidden;
  width: 46.5px;
  height: 60px;
  font-family: "Orbitron", sans-serif;
  font-size: 0.75em;
  text-align: center;
  text-shadow: 0 0 0.2rem currentColor;
  border: aqua solid 1px;
  box-shadow: 0 0 0.25rem 0 aqua;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 5px;
  margin: 7.5px;
  margin-top: 10px;
  padding: 10px;
  transform: translateX(-100px);
  filter: blur(20px);
  opacity: 0;
}

.tech-icon-2 {
  position: relative;
  overflow: hidden;
  width: 46.5px;
  height: 60px;
  font-family: "Orbitron", sans-serif;
  font-size: 0.75em;
  text-align: center;
  text-shadow: 0 0 0.2rem currentColor;
  border: aqua solid 1px;
  box-shadow: 0 0 0.25rem 0 aqua;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 5px;
  margin: 7.5px;
  margin-top: 10px;
  padding: 10px;
  transition: 0.5s;
  transition-delay: 1s;
}

.tech-icon-2:nth-child(2) {
  transition-delay: 1.1s;
}

.tech-icon-before-2 {
  position: relative;
  overflow: hidden;
  width: 46.5px;
  height: 60px;
  font-family: "Orbitron", sans-serif;
  font-size: 0.75em;
  text-align: center;
  text-shadow: 0 0 0.2rem currentColor;
  border: aqua solid 1px;
  box-shadow: 0 0 0.25rem 0 aqua;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 5px;
  margin: 7.5px;
  margin-top: 10px;
  padding: 10px;
  transform: translateX(-100px);
  filter: blur(20px);
  opacity: 0;
}

.js-title {
  transform: translateX(-7px);
}

#JS {
  color: #f0db4f;
  animation-delay: 5s;
}

#HTML {
  color: #f06529;
}

#css {
  color: #2965f1;
}

#react {
  color: #61dbfb;
}

#node {
  color: #3c873a;
}

#sql {
  color: #0064a5;
}

.icon-text {
  font-size: 0.525rem;
  color: cyan;
  opacity: 0.65;
  text-shadow: none;
}

.flash-JS::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 98%;
  background: rgba(255, 255, 255, 0.5);
  transform: skew(45deg) translateX(150px);
  animation: flash-JS 5s linear infinite;
}

.flash-css::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 98%;
  background: rgba(255, 255, 255, 0.5);
  transform: skew(45deg) translateX(150px);
  animation: flash-css 12s linear infinite;
}

.flash-sql::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 98%;
  background: rgba(255, 255, 255, 0.5);
  transform: skew(45deg) translateX(150px);
  animation: flash-sql 8.3s linear infinite;
}

@keyframes flash-JS {
  90% {
    transform: skew(45deg) translateX(150px);
  }
  100% {
    transform: skew(45deg) translateX(-150px);
  }
}

@keyframes flash-css {
  95% {
    transform: skew(45deg) translateX(150px);
  }
  100% {
    transform: skew(45deg) translateX(-150px);
  }
}

@keyframes flash-sql {
  92% {
    transform: skew(45deg) translateX(150px);
  }
  100% {
    transform: skew(45deg) translateX(-150px);
  }
}

/* @property --gradient-angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
} */

:root {
  --gradient-angle: 0deg;
  --clr-3: #5ddcff;
  --clr-4: #3c67e3;
  --clr-5: #4e00c2;
  /* --clr-3: magenta;
  --clr-4: #3c67e3;
  --clr-5: purple; */
  /* --clr-3: lawngreen;
  --clr-4: green;
  --clr-5: darkgreen; */
}

.about-con-side {
  /* width: 50%; */
  width: 100%;
  height: 100%;
  /* border: aqua solid 2px; */
  /* border: 2px solid aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* opacity: 1; */

  transition: 0.5s;
}

.about-con-side-before {
  /* width: 50%; */
  width: 100%;
  height: 100%;
  /* border: aqua solid 2px; */
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(200px);
  opacity: 0;
  transition: 0.5s;
}

.about-pic-side {
  /* width: 50%; */
  width: 100%;
  height: 100%;
  /* border: lawngreen solid 2px; */
  /* border: 2px solid aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;

  transition: 0.5s;
}

.about-pic-side-before {
  /* width: 50%; */
  width: 100%;
  height: 100%;
  /* border: lawngreen solid 2px; */
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(200px);
  opacity: 0;
  transition: 0.5s;
}

.about-con {
  /* height: 85vh; */
  height: 750px;
  aspect-ratio: 1 / 2.45;
  /* background-color: rgb(0, 0, 70); */
  background: linear-gradient(to bottom right, navy, rgb(0, 0, 70));
  /* background-image: url("rain-night-city.gif"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.5rem;

  position: relative;
}

.about-con::before,
.about-con::after {
  content: "";
  position: absolute;
  inset: -0.7125rem;
  z-index: -1;
  background: conic-gradient(
    from var(--gradient-angle),
    var(--clr-3),
    var(--clr-4),
    var(--clr-5),
    var(--clr-4),
    var(--clr-3)
  );
  border-radius: inherit;
  animation: rotation 5s linear infinite;
}

.about-con::after {
  filter: blur(3rem);
}

@keyframes rotation {
  /* 0% {
    --gradient-angle: 0deg;
  }
  100% {
    --gradient-angle: 360deg;
  } */
  from {
    --gradient-angle: 0deg;
  }
  to {
    --gradient-angle: 360deg;
  }
}

.back-cover::before {
  width: 99.99%;
  height: 99.99%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(40, 1, 55, 0.9);
  animation: show-back 0.5s forwards ease-in;
  animation-delay: 0.75s;
}
@keyframes show-back {
  100% {
    transform: translateX(-100%);
  }
}

.show-text {
  /* opacity: 0.99; */
  animation: back-op 0.1s forwards ease-in;
  animation-delay: 0.5s;
}

@keyframes back-op {
  99% {
    opacity: 0.99;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 1200px) {
  .about-page {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .about-con-side {
    /* width: 100%; */
    width: 50%;
    height: 100%;
    /* border: 2px solid aqua; */
  }

  .about-con-side-before {
    /* width: 100%; */
    width: 50%;
    height: 100%;
  }

  .about-pic-side {
    /* border: 2px solid aqua; */
    /* width: 100%; */
    width: 50%;
    height: 100%;
  }

  .about-pic-side-before {
    /* width: 100%; */
    width: 50%;
    height: 100%;
  }

  .about-con {
    /* height: 25vh; */
    height: 85vh;
    aspect-ratio: 1 / 1.25;
    /* background-color: rgb(0, 0, 70); */
    background: linear-gradient(to bottom right, navy, rgb(0, 0, 70));
    /* background-image: url("rain-night-city.gif"); */
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.5rem;

    position: relative;
  }

  .about-me {
    font-size: 3rem;
  }

  .about-me-before {
    font-size: 3rem;
  }

  .about-text,
  .about-text-2,
  .about-text-3,
  .about-text-before,
  .about-text-before-2,
  .about-text-before-3 {
    font-size: 1.11rem;
  }

  .tech-icon {
    position: relative;
    overflow: hidden;
    width: 75px;
    height: 80px;
    font-family: "Orbitron", sans-serif;
    font-size: 1em;
    text-align: center;
    text-shadow: 0 0 0.2rem currentColor;
    border: aqua solid 1px;
    box-shadow: 0 0 0.25rem 0 aqua;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 5px;
    margin: 20px;
    margin-top: 10px;
    padding: 10px;
  }

  .tech-icon-before {
    position: relative;
    overflow: hidden;
    width: 75px;
    height: 80px;
    font-family: "Orbitron", sans-serif;
    font-size: 1em;
    text-align: center;
    text-shadow: 0 0 0.2rem currentColor;
    border: aqua solid 1px;
    box-shadow: 0 0 0.25rem 0 aqua;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 5px;
    margin: 20px;
    margin-top: 10px;
    padding: 10px;
  }

  .tech-icon-2 {
    position: relative;
    overflow: hidden;
    width: 75px;
    height: 80px;
    font-family: "Orbitron", sans-serif;
    font-size: 1em;
    text-align: center;
    text-shadow: 0 0 0.2rem currentColor;
    border: aqua solid 1px;
    box-shadow: 0 0 0.25rem 0 aqua;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 5px;
    margin: 20px;
    margin-top: 10px;
    padding: 10px;
  }

  .tech-icon-before-2 {
    position: relative;
    overflow: hidden;
    width: 75px;
    height: 80px;
    font-family: "Orbitron", sans-serif;
    font-size: 1em;
    text-align: center;
    text-shadow: 0 0 0.2rem currentColor;
    border: aqua solid 1px;
    box-shadow: 0 0 0.25rem 0 aqua;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 5px;
    margin: 20px;
    margin-top: 10px;
    padding: 10px;
  }

  .icon-text {
    font-size: 0.75rem;
  }

  .js-title {
    transform: translateX(0);
  }
}

@media screen and (min-width: 768px) and (min-height: 1024px) {
  .js-title {
    transform: translateX(0);
  }
}

@media screen and (min-height: 1000px) {
  .about-con {
    height: 70vh;
    aspect-ratio: 1 / 1.35;
  }

  .about-me {
    font-size: 2.625rem;
  }

  .about-me-before {
    font-size: 2.625rem;
  }

  .about-text,
  .about-text-2,
  .about-text-3,
  .about-text-before,
  .about-text-before-2,
  .about-text-before-3 {
    font-size: 1.11rem;
  }
}

@media screen and (min-height: 1100px) {
  .about-con {
    height: 70vh;
    aspect-ratio: 1 / 1.75;
  }

  .tech-icon {
    position: relative;
    overflow: hidden;
    width: 75px;
    height: 80px;
    font-family: "Orbitron", sans-serif;
    font-size: 1em;
    text-align: center;
    text-shadow: 0 0 0.2rem currentColor;
    border: aqua solid 1px;
    box-shadow: 0 0 0.25rem 0 aqua;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 5px;
    margin: 7.5px;
    margin-top: 10px;
    padding: 10px;
  }

  .tech-icon-before {
    position: relative;
    overflow: hidden;
    width: 75px;
    height: 80px;
    font-family: "Orbitron", sans-serif;
    font-size: 1em;
    text-align: center;
    text-shadow: 0 0 0.2rem currentColor;
    border: aqua solid 1px;
    box-shadow: 0 0 0.25rem 0 aqua;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 5px;
    margin: 7.5px;
    margin-top: 10px;
    padding: 10px;
  }

  .tech-icon-2 {
    position: relative;
    overflow: hidden;
    width: 75px;
    height: 80px;
    font-family: "Orbitron", sans-serif;
    font-size: 1em;
    text-align: center;
    text-shadow: 0 0 0.2rem currentColor;
    border: aqua solid 1px;
    box-shadow: 0 0 0.25rem 0 aqua;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 5px;
    margin: 7.5px;
    margin-top: 10px;
    padding: 10px;
  }

  .tech-icon-before-2 {
    position: relative;
    overflow: hidden;
    width: 75px;
    height: 80px;
    font-family: "Orbitron", sans-serif;
    font-size: 1em;
    text-align: center;
    text-shadow: 0 0 0.2rem currentColor;
    border: aqua solid 1px;
    box-shadow: 0 0 0.25rem 0 aqua;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 5px;
    margin: 7.5px;
    margin-top: 10px;
    padding: 10px;
  }

  .icon-text {
    font-size: 0.5rem;
  }
}

@media screen and (min-height: 1200px) {
  .about-con {
    height: 62.5vh;
    aspect-ratio: 1 / 1.75;
  }
}

@media screen and (min-height: 500px) and (max-height: 600px) {
  .about-page {
    height: 200vh;
  }

  .about-con {
    height: 112vh;
  }
}

@media screen and (min-height: 660px) and (max-height: 680px) {
  .about-page {
    height: 140vh;
  }

  .about-con {
    height: 750px;
  }
}

@media screen and (min-height: 680px) and (max-height: 700px) {
  .about-con {
    height: 108vh;
  }
}

@media screen and (min-height: 700px) and (max-height: 720px) {
  .about-con {
    height: 105vh;
  }
}

@media screen and (min-height: 720px) and (max-height: 740px) {
  .about-con {
    height: 102.5vh;
  }
}

@media screen and (min-height: 740px) and (max-height: 760px) {
  .about-con {
    height: 100vh;
  }
}

@media screen and (min-height: 760px) and (max-height: 780px) {
  .about-con {
    height: 97.5vh;
  }
}

@media screen and (min-height: 780px) and (max-height: 800px) {
  .about-con {
    height: 95vh;
  }
}

@media screen and (min-height: 800px) and (max-height: 820px) {
  .about-con {
    height: 92.5vh;
  }
}

@media screen and (min-height: 820px) and (max-height: 840px) {
  .about-con {
    height: 90vh;
  }
}

@media screen and (min-height: 840px) and (max-height: 860px) {
  .about-con {
    height: 750px;
  }
}

@media screen and (min-height: 860px) and (max-height: 869px) {
  .about-con {
    height: 87vh;
  }
}

@media screen and (max-height: 500px) {
  .about-page {
    height: 400vh;
  }

  .about-con {
    height: 210vh;
  }

  .about-pic-side {
    height: 190vh;
  }
}

@media screen and (min-width: 1050px) and (max-width: 1200px) and (min-height: 800px) and (max-height: 900px) {
  .about-con {
    aspect-ratio: 1 / 1.5;
  }
}
