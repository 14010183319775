@import url("https://fonts.cdnfonts.com/css/neon-future");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap");
@import url("https://fonts.cdnfonts.com/css/galactic-storm");

.home {
  width: 100%;
  height: 100vh;
}

:root {
  font-size: 16px;
}

.p {
  color: aqua;
  font-family: "Neon Future", sans-serif;
  font-size: 9rem;
  letter-spacing: 2px;
  text-shadow: 0 0 0.25em currentColor;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 10;
}

.swipe {
  z-index: 11;
}

.swipe::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 100%;
  width: 0%;
  height: 49.5%;
  background-color: lawngreen;
  box-shadow: 0 0 0.25em 0 lawngreen;
  animation: swipe 0.65s ease-out 1s forwards;
  animation-delay: 2.45s;
}

.swipe1 {
  z-index: 11;
}

.swipe1::after {
  display: block;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 100%;
  width: 0%;
  height: 49.5%;
  /* background-color: rgb(173, 64, 224);
  box-shadow: 0 0 0.25em 0 rgb(173, 64, 224); */
  background-color: cyan;
  box-shadow: 0 0 0.25em 0 cyan;
  animation: swipet 0.65s ease-out 1s forwards;
  animation-delay: 2.55s;
}

@keyframes swipe {
  0% {
    right: 100%;
    left: 0;
    width: 0%;
  }
  50% {
    right: 0;
    left: 0;
    width: 100%;
  }
  100% {
    right: 0;
    left: 100%;
    width: 0%;
  }
}

@keyframes swipet {
  0% {
    right: 100%;
    left: 0;
    width: 0%;
  }
  50% {
    right: 0;
    left: 0;
    width: 100%;
  }
  100% {
    right: 0;
    left: 100%;
    width: 0%;
  }
}

.big {
  opacity: 0;
  animation: fade 0.01s ease-out 1.75s forwards;
  animation-delay: 2.7s;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.flick {
  animation: flicker 1.5s infinite;
}

.flick1 {
  animation: flicker1 1.75s infinite;
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flicker1 {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.title {
  margin-top: 10px;
  font-size: 0.6em;
  color: gold;
  opacity: 0;
  animation: fadet 0.01s ease-out 1.75s forwards;
  animation-delay: 2.8s;
}

@keyframes fadet {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.test {
  margin: 100px auto;
  margin-bottom: 3rem;
  margin-top: 9.75rem;
  position: relative;
  width: 1200px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  animation: fil 0.5s ease-in;
  animation-fill-mode: forwards;
  animation-delay: 2s;
}

.test svg,
.test svg rect {
  position: absolute;
  width: 100%;
  height: 100%;
  fill: transparent;
  border-radius: 50px;
}

.test svg rect {
  stroke: #d400d4;
  stroke-width: 30;
  stroke-linejoin: round;
  transition: 1.5s;
  stroke-dasharray: 3500;
  stroke-dashoffset: 3500;
  animation: neon 0.5s linear;
  animation-fill-mode: forwards;
  filter: brightness(100%);
  animation-delay: 2s;
}

@keyframes neon {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fil {
  100% {
    background-color: rgba(20, 20, 20, 0.8);
    box-shadow: 0 0 1em 0 #d400d4;
  }
}

.test1 {
  margin: 0 auto;
  filter: brightness(100%);
  width: 1200px;
  height: 400px;
  animation: pulse 4s infinite;
  animation-delay: 2.5s;
  border-radius: 50px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 1em 0 #d400d4;
    filter: brightness(200%);
  }
  45% {
    box-shadow: 0 0 0.5em 0 #d400d4;
    filter: brightness(90%);
  }
  47.5% {
    box-shadow: 0 0 1em 0 #d400d4;
    filter: brightness(200%);
  }
  50% {
    box-shadow: 0 0 0.5em 0 #d400d4;
    filter: brightness(90%);
  }
  52.5% {
    box-shadow: 0 0 1em 0 #d400d4;
    filter: brightness(200%);
  }
  55% {
    box-shadow: 0 0 0.5em 0 #d400d4;
    filter: brightness(90%);
  }
  100% {
    box-shadow: 0 0 1em 0 #d400d4;
    filter: brightness(200%);
  }
}

.card {
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  backdrop-filter: blur(20px);
  background-color: rgba(20, 20, 20, 0.5);
  border: solid aqua 2px;
  border-radius: 15px;
  box-shadow: 0 0 0.5em 0 aqua;
  color: lime;
  width: 1150px;
  height: 140px;
  font-size: 1.5rem;
  font-family: "Orbitron", sans-serif;
  text-shadow: 0 0 0.75rem currentColor;
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: left;
  transform: scale(0);
  animation: about-intro 1s forwards ease-in;
  animation-delay: 3.6s;
}

.intro {
  margin-top: 15px;
  opacity: 0;
  animation: show 0.5 forwards ease-in;
  animation-delay: 4.7s;
}

@keyframes show {
  50% {
    opacity: 1;
  }
}

@keyframes about-intro {
  50% {
    transform: scale(1, 0);
    transform-origin: top;
  }
  75% {
    transform: scale(1, 1.1);
    transform-origin: top;
  }
  100% {
    transform: scale(1, 1);
    transform-origin: top;
  }
}

.cursor {
  font-family: "Galactic Storm", sans-serif;
  font-size: 1.8rem;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: lime;
  }
}

@media screen and (max-width: 1400px) {
  .card {
    width: 950px;
    height: 170px;
  }
}

@media screen and (max-width: 1200px) {
  .p {
    font-size: 5.5em;
  }
  .test1 {
    width: 750px;
    height: 300px;
    border-radius: 50px;
  }

  .test {
    border-radius: 50px;
    width: 750px;
    height: 300px;
  }

  .test svg,
  .test svg rect {
    border-radius: 50px;
  }

  .test svg rect {
    stroke-width: 20;
    /* animation: neon-mob 3s linear;
    animation-fill-mode: forwards;
    animation-delay: 5.5s; */
  }

  /* @keyframes neon-mob {
    100% {
      stroke-dashoffset: 0;
    }
  } */

  .card {
    width: 680px;
    height: 300px;
    font-size: 1.8rem;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 6.75rem;
    /* margin-top: 100px; */
  }

  /* .cursor {
    font-size: 0.975rem;
  } */
}

@media screen and (max-width: 1180px) and (max-height: 820px) {
  .card {
    font-size: 1.5rem;
    margin-top: 3rem;
    height: 200px;
  }
}

@media screen and (max-width: 1000px) {
  .p {
    font-size: 4.5em;
  }
  .test1 {
    width: 600px;
    height: 220px;
    border-radius: 40px;
  }

  .test {
    border-radius: 40px;
    width: 600px;
    height: 220px;
  }

  .test svg,
  .test svg rect {
    border-radius: 40px;
  }

  .test svg rect {
    stroke-width: 15;
    /* animation: neon-mob 3s linear;
    animation-fill-mode: forwards;
    animation-delay: 5.5s; */
  }

  /* @keyframes neon-mob {
    100% {
      stroke-dashoffset: 0;
    }
  } */

  .card {
    width: 550px;
    height: 300px;
    font-size: 1.55rem;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 6.75rem;
  }

  /* .cursor {
    font-size: 0.975rem;
  } */
}

@media screen and (max-width: 700px) {
  .p {
    font-size: 2.6em;
  }
  .test1 {
    width: 370px;
    height: 150px;
    border-radius: 30px;
  }

  .test {
    border-radius: 30px;
    width: 370px;
    height: 150px;
  }

  .test svg,
  .test svg rect {
    border-radius: 30px;
  }

  .test svg rect {
    stroke-width: 15;
    animation: neon-mob 1s linear;
    animation-fill-mode: forwards;
    animation-delay: 2s;
  }

  @keyframes neon-mob {
    100% {
      stroke-dashoffset: 0;
    }
  }

  .card {
    width: 290px;
    height: 300px;
    font-size: 1.2rem;
    margin-top: 3rem;
    animation-delay: 3.4s;
  }

  .cursor {
    font-size: 1.45rem;
  }
}

@media screen and (max-width: 390px) {
  .p {
    font-size: 2.45rem;
  }

  .test,
  .test1 {
    width: 350px;
  }
}
