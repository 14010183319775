.conSocial {
  width: 150px;
  height: 270px;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
  transform: translateY(100%);
  overflow: hidden;
  animation: slide-up 0.25s forwards ease-in;
  animation-delay: 3.6s;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.icons {
  width: 100px;
  height: 170px;
  margin-left: 21px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  /* background-color: rgba(20, 20, 20, 0.5); */
  border: solid lawngreen 4px;
  border-radius: 10px;
  box-shadow: 0 0 0.75em 0 lawngreen;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.icons ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.icons ul li {
  margin-bottom: 17.5px;
  margin-top: 17.5px;
}

.icons ul a {
  text-decoration: none;
}

.github {
  color: #d400d4;
  text-shadow: 0 0 0.15rem currentColor;
  transform: translateX(-300%);
  animation: slide-github 0.25s forwards ease-in;
  animation-delay: 3.85s;
}

@keyframes slide-github {
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}

.git-blur:hover {
  /* font-size: 1.5rem; */
  transform: scale(1.5);
}

.github:hover {
  filter: drop-shadow(0 0 20px currentColor) drop-shadow(0 0 40px currentColor)
    drop-shadow(0 0 60px currentColor);
}

.git-blur {
  filter: blur(5px);
  opacity: 0;
  animation: git-unblur 0.5s forwards ease-in;
  animation-delay: 3.85s;
}

@keyframes git-unblur {
  50% {
    filter: blur(0);
    opacity: 1;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
}

.linkedin {
  color: #0072b1;
  text-shadow: 0 0 0.15rem currentColor;
  transform: translateX(-300%);
  animation: slide-linkedin 0.25s forwards ease-in;
  animation-delay: 3.95s;
}

@keyframes slide-linkedin {
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}

.link-blur:hover {
  transform: scale(1.5);
}

.linkedin:hover {
  /* font-size: 1.5rem; */
  filter: drop-shadow(0 0 20px currentColor) drop-shadow(0 0 40px currentColor)
    drop-shadow(0 0 60px currentColor);
}

.link-blur {
  filter: blur(5px);
  opacity: 0;
  animation: link-unblur 0.25s forwards ease-in;
  animation-delay: 3.95s;
}

@keyframes link-unblur {
  50% {
    filter: blur(0);
    opacity: 1;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
}

.CV {
  color: cyan;
  text-shadow: 0 0 0.15rem currentColor;
  transform: translateX(-300%);
  animation: slide-CV 0.25s forwards ease-in;
  animation-delay: 4.05s;
}

@keyframes slide-CV {
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0);
  }
}

.CV-blur:hover {
  transform: scale(1.5);
}

.CV:hover {
  /* font-size: 1.5rem; */
  filter: drop-shadow(0 0 20px currentColor) drop-shadow(0 0 40px currentColor)
    drop-shadow(0 0 60px currentColor);
}

.CV-blur {
  filter: blur(5px);
  opacity: 0;
  animation: CV-unblur 0.25s forwards ease-in;
  animation-delay: 4.05s;
}

@keyframes CV-unblur {
  50% {
    filter: blur(0);
    opacity: 1;
  }
  100% {
    filter: blur(0);
    opacity: 1;
  }
}

.line {
  width: 8px;
  height: 95px;
  margin-left: 68px;
  background-color: lawngreen;
  box-shadow: 0 0 0.5em 0 lawngreen;
}

@media screen and (max-width: 1000px) {
  .conSocial {
    /* border: 2px solid aqua; */
    width: 310px;
    height: 150px;
  }

  .icons {
    /* transform: rotate(90deg); */
    position: absolute;
    margin-left: 0;
    bottom: 25px;
    right: 0;
    width: 210px;
    height: 100px;
    background: rgba(0, 0, 0, 0.8);
  }

  .icons ul li {
    margin-left: 17.5px;
    margin-right: 17.5px;
  }

  .icons ul a div {
    display: inline-block;
  }

  .line {
    width: 95px;
    height: 8px;
    margin-left: 0;
    position: absolute;
    left: 0;
    bottom: 75px;
  }

  .github,
  .linkedin,
  .CV {
    transform: translateX(-100%);
  }
}

@media screen and (min-width: 700px) and (max-width: 800px) and (min-height: 1000px) and (max-height: 1100px) {
  .conSocial {
    width: 489px;
  }

  .line {
    width: 274px;
  }
}

@media screen and (min-width: 800px) and (max-width: 900px) and (min-height: 1100px) and (max-height: 1200px) {
  .conSocial {
    width: 515px;
  }

  .line {
    width: 300px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1000px) and (min-height: 1300px) and (max-height: 1400px) {
  .conSocial {
    width: 561px;
  }

  .line {
    width: 346px;
  }
}

@media screen and (max-width: 380px) {
  .line {
    width: 80px;
  }

  .conSocial {
    width: 294px;
  }
}

@media screen and (max-height: 500px) {
  .conSocial {
    width: 150px;
    height: 270px;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9;
    transform: translateY(100%);
    overflow: hidden;
    animation: slide-up 0.75s forwards ease-in;
    animation-delay: 8.5s;
  }

  .icons {
    width: 80px;
    height: 130px;
    bottom: 40px;
    right: 32.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .icons ul li {
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .line {
    width: 8px;
    height: 40px;
    margin-left: 68px;
    bottom: 0;
  }
}
