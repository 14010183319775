.popup {
  /* overflow: hidden; */
  transform: translateX(110%);
  position: fixed;
  top: 10%;
  right: 2%;
  width: 350px;
  height: 80px;
  padding-left: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  /* border: 2px solid lawngreen; */
  text-shadow: 0 0 0.1rem currentColor;
  border-radius: 10px;
  /* box-shadow: 0 0 0.5rem 0 lawngreen; */
  font-family: "Orbitron", sans-serif;
  text-align: left;
  color: lawngreen;
  letter-spacing: 0.1rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  animation: popup 3.5s forwards ease-in;
}

@keyframes popup {
  3.6% {
    transform: translateX(0);
  }
  5.35% {
    transform: translateX(10%);
  }
  7.14% {
    transform: translateX(0);
  }
  95% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(110%);
  }
}

.popup-err {
  /* overflow: hidden; */
  transform: translateX(110%);
  position: fixed;
  top: 10%;
  right: 2%;
  width: 350px;
  height: 80px;
  padding-left: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  /* border: 2px solid lawngreen; */
  text-shadow: 0 0 0.1rem currentColor;
  border-radius: 10px;
  /* box-shadow: 0 0 0.5rem 0 lawngreen; */
  font-family: "Orbitron", sans-serif;
  text-align: left;
  color: orangered;
  letter-spacing: 0.1rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  animation: popup-err 3.5s forwards ease-in;
}

@keyframes popup-err {
  3.6% {
    transform: translateX(0);
  }
  5.35% {
    transform: translateX(10%);
  }
  7.14% {
    transform: translateX(0);
  }
  95% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(110%);
  }
}

@keyframes popout {
}

/* .popup-inner {
  width: 100%;
  text-align: left;
} */

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 0.75rem;
  color: gray;
  border: none;
  box-shadow: none;
  background: none;
  font-family: "Orbitron", sans-serif;
}

.close-btn:hover {
  cursor: pointer;
}

.notice {
  position: absolute;
  top: 35%;
  left: 15%;
  font-size: 0.75rem;
  color: white;
  text-shadow: none;
}

.bar {
  width: 100%;
  height: 5px;
  background-color: lawngreen;
  box-shadow: 0 0 1rem 0 lawngreen;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  animation: message-bar 3s forwards ease-in;
  animation-delay: 0.25s;
}

@keyframes message-bar {
  100% {
    width: 0;
  }
}

.bar-err {
  width: 100%;
  height: 5px;
  background-color: orangered;
  box-shadow: 0 0 1rem 0 orangered;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  animation: message-bar-err 3s forwards ease-in;
  animation-delay: 0.25s;
}

@keyframes message-bar-err {
  100% {
    width: 0;
  }
}

@media screen and (max-width: 1200px) {
  .popup,
  .popup-err {
    width: 325px;
  }

  .notice {
    font-size: 0.65rem;
  }
}
